
























































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN"
    })
  },
  methods: {
    onDownload(url: string) {
      download(url);
    },
    onOpen(url: string) {
      window.open(url, "_blank");
    },
    onAdmin() {
      const element = document.getElementById("rt-admin");
      if (element) {
        element.scrollIntoView({
          behavior: "smooth"
        });
      }
    }
  },
  mounted() {
    // if (!this.isCN) {
    //   this.$router.back();
    //   this.onOpen("https://bit.ly/3PHs2jO");
    // }
  }
});
