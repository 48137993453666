var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"black-diamond-75"},[_c('v-header',[_c('div',{staticClass:"item",on:{"click":function($event){return _vm.onDownload(
          'https://cdn-www.angrymiao.com/pdf/BlackDiamond75RTUserManual%E7%94%A8%E6%88%B7%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf'
        )}}},[_c('i',{staticClass:"iconfont icon-download"}),_vm._v(" "+_vm._s(_vm.$t("userManual"))+" ")]),(false)?_c('div',{staticClass:"item",on:{"click":function($event){return _vm.onOpen('https://www.angrymiao.com/verify/black-diamond-75/')}}},[_vm._v(" "+_vm._s(_vm.$t("verify"))+" ")]):_vm._e(),(false)?_c('div',{staticClass:"item",staticStyle:{"font-style":"italic","font-weight":"bold","font-family":"Helvetica-Now-Display-XBold"},on:{"click":function($event){return _vm.onOpen('https://diy.angrymiao.com/keyboard/black-diamond-75/')}}},[_vm._v(" > DIY ")]):_vm._e(),(false)?_c('div',{staticClass:"item",staticStyle:{"font-style":"italic","font-weight":"bold","font-family":"Helvetica-Now-Display-XBold"},on:{"click":function($event){return _vm.onOpen('https://www.angrymiao.com/am-master/')}}},[_vm._v(" > AM MASTER ")]):_vm._e(),(_vm.isCN)?_c('div',{staticClass:"item",staticStyle:{"font-style":"italic","font-weight":"bold","font-family":"Helvetica-Now-Display-XBold"},on:{"click":_vm.onAdmin}},[_vm._v(" 管理软件 ")]):_vm._e(),(_vm.isCN)?_c('div',{staticClass:"buy",on:{"click":function($event){return _vm.onOpen(
          _vm.isCN
            ? 'https://item.taobao.com/item.htm?ft=t&id=725119750869'
            : 'https://item.taobao.com/item.htm?ft=t&id=725119750869'
        )}}},[_vm._v(" "+_vm._s(_vm.$t("buy"))+" ")]):_vm._e()]),(_vm.isCN)?[_c('article',[_c('img',{staticClass:"fall",attrs:{"src":require("../assets/black-diamond-75-rt/cn_01.png"),"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":require("../assets/black-diamond-75-rt/cn_02.png"),"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":require("../assets/black-diamond-75-rt/cn_03.png"),"alt":""}}),_c('video',{staticClass:"fall video",attrs:{"src":require("../assets/black-diamond-75-rt/cn_video_01.mp4"),"controls":""}}),_c('img',{staticClass:"fall",attrs:{"src":require("../assets/black-diamond-75-rt/cn_04.jpeg"),"alt":""}}),_c('img',{staticClass:"fall",attrs:{"src":require("../assets/black-diamond-75-rt/cn_05.png"),"alt":""}}),_c('video',{staticClass:"fall video",attrs:{"src":require("../assets/black-diamond-75-rt/cn_video_02.mp4"),"controls":""}}),_c('img',{staticClass:"fall",attrs:{"src":require("../assets/black-diamond-75-rt/cn_06.png"),"alt":""}}),_c('img',{staticClass:"fall",staticStyle:{"position":"relative","z-index":"0"},attrs:{"src":require("../assets/black-diamond-75-rt/cn_07.png"),"alt":""}}),_c('div',{staticClass:"fall",attrs:{"id":"rt-admin"}},[_c('div',{on:{"click":function($event){return _vm.onDownload(
              'https://cdn-www.angrymiao.com/dry---studio/RT_Admin_1.0.4.exe'
            )}}},[_c('div',{staticStyle:{"height":"0","text-align":"center","color":"#cd2d25"}},[_vm._v(" V1.0.4 ")])]),_c('div',{on:{"click":function($event){return _vm.onDownload(
              'https://cdn-www.angrymiao.com/dry---studio/RT_Admin_1.0.4.dmg'
            )}}},[_c('div',{staticStyle:{"height":"0","text-align":"center","color":"#cd2d25"}},[_vm._v(" V1.0.4 ")])]),_c('div',{on:{"click":function($event){return _vm.onDownload(
              'https://cdn-www.angrymiao.com/dry---studio/update_202408021929.rar'
            )}}}),_c('div',{on:{"click":function($event){return _vm.onDownload(
              'https://cdn-www.angrymiao.com/dry---studio/AM75_App_v0.0.1_20240911a.bin'
            )}}})])])]:void 0,_c('v-footer')],2)}
var staticRenderFns = []

export { render, staticRenderFns }